import { template as template_16c520754aea472c9a62efb1f3cc6f53 } from "@ember/template-compiler";
const FKLabel = template_16c520754aea472c9a62efb1f3cc6f53(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
