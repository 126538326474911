import { template as template_58e421d917ec47aaa01a2ae885b951cf } from "@ember/template-compiler";
const FKControlMenuContainer = template_58e421d917ec47aaa01a2ae885b951cf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
